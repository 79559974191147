<!-- 
    分页
    <Pagination :pageSize='8' :total='total' @getPage='getPage'></Pagination> 
-->
<template>
    <div class="Pagination">
        <el-pagination
            layout="pager"
            background
            :page-size="pageSize"
            :total="total"
            :current-page="page"
            hide-on-single-page
            @current-change="pageChange"
        >
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
            pageSize: Number,
            total: Number,
            page: Number,
        },
        methods: {
            pageChange(e) {
                this.$emit("getPage", e);
            },
        },
    };
</script>

<style lang="less" scoped>
.Pagination {
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
        background: red;
    }
    /deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #fff;
    }
    /deep/.el-pager {
        display: flex;
        li {
            border-radius: 100%;
            width: 47px;
            height: 47px;
            background: #b9b9b9;
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>