<template>
    <div class="flex-alc column NoData">
        <img
            src="@/assets/nodata_1@2x.png"
            v-if="img"
            class="noMasterImg"
            alt=""
        />
        <div class="tipText" :class="{ gray: gray }">{{ text }}</div>
    </div>
</template>

<script>
    export default {
        name: "NoData",
        props: {
            text: {
                type: String,
                default: "您还没有收藏课程",
            },
            img: {
                type: Boolean,
                default: true,
            },
            gray: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="less" scoped>
.NoData {
    width: 100%;
    .noMasterImg {
        width: 134px;
        height: 112px;
        margin-top: 66px;
    }
    .tipText {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        margin-top: 31px;
        margin-bottom: 21px;
    }
    .gray {
        color: #aaa;
    }
}
</style>